.update-button {
    margin-left: 4px;
    color: #515455 !important;
    box-shadow: none !important;
    background: #e1cfbb !important;
    border-color: #e1cfbb !important;
}
.btn-group .btn {
    margin: 0 !important;
    color: #515455 !important;
    background: #f0e5dd !important;
    border-color: #f0e5dd !important;
    box-shadow: none !important;
}
.update-button:hover {
    background: #f0e5dd !important;
    border-color: #f0e5dd !important;
}
.btn-group .btn:hover {
    background: #e1cfbb !important;
    border-color: #e1cfbb !important;
}
.btn-group > .btn:not(:last-child) {
    border-right: 1px solid #515455 !important;
}
@media screen and (max-width: 28rem) {
    .update-button .text {
        display: none;
    }
}
